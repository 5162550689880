import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router";
import { Layout } from "./components/Layout";
import { ProfileProvider } from "./Profile";
import Loading from "./components/Loading";
import CreateLeadList from "./components/LeadCrawler/CreateLeadList";

const Home = lazy(() => import("./components/Home"));
const CategoryCreator = lazy(() => import("./components/CategoryCreator"));
const TopicCreator = lazy(() => import("./components/TopicCreator"));
const TerraformPlans = lazy(() => import("./components/TerraformPlans/Router"));
const LeadList = lazy(() => import("./components/LeadCrawler/LeadList"));
const AzDoGoogleChat = lazy(() => import("./components/AzDoGoogleChat"));

export default function App() {
  return (
    <ProfileProvider>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terraform-plans/*" element={<TerraformPlans />} />
            <Route
              path="/discourse-category-creator/*"
              element={<CategoryCreator />}
            />
            <Route
              path="/discourse-topic-creator/*"
              element={<TopicCreator />}
            />
            <Route path="/lead-list/*" element={<LeadList />} />
            <Route path="/lead-crawler/*" element={<CreateLeadList />} />
            <Route path="/azdo-googlechat/*" element={<AzDoGoogleChat />} />
          </Routes>
        </Suspense>
      </Layout>
    </ProfileProvider>
  );
}
