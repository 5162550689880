import React, { useState, useEffect } from "react";

const Loading = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {show && (
        <div className="relative pt-1 mb-6 max-w-7xl mx-auto">
          <div className="flex justify-center items-center">
            <div
              className="animate-spin rounded-full h-32 w-32 border-b-4 border-green-500"
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
