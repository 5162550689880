import React, { Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import { classNames, useUrlParams } from "./Blocks";
import { Popover, Transition } from "@headlessui/react";
import {
  FaAngleDown,
  FaRegWindowClose,
  FaPlusSquare,
  FaSpider,
  FaFileCsv,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../Images/plenty_logo_white.png";

const MenuItem = (props) => (
  <NavLink activeClassName="is-active" {...props}>
    {props.children}
  </NavLink>
);

export function NavMenu() {
  const { embedded: isEmbedded } = useUrlParams();

  if (isEmbedded == "1") return <></>;

  return (
    <nav>
      <div className="flex-1 flex items-center justify-between sm:items-stretch sm:justify-start">
        <Link
          to="/"
          className="flex-shrink-0 flex items-center text-white font-bold"
        >
          <img
            alt="plentymarktes logo"
            src={logo}
            className="h-7 flex-shrink-0 flex items-center"
          />
        </Link>
        <PageMenu />
      </div>
    </nav>
  );
}

const resources = [
  {
    name: "Category Creator",
    description: "Create new category for customers in Discourse",
    href: "/discourse-category-creator",
    icon: FaPlusSquare,
  },
  {
    name: "Topic Creator",
    description: "Create new onboarding topic in Discourse",
    href: "/discourse-topic-creator",
    icon: FaPlusSquare,
  },
];

const crawlerRessources = [
  {
    name: "Lead Crawler",
    description: "Create a new list of leads",
    href: "/lead-crawler",
    icon: FaSpider,
  },
  {
    name: "Lead List",
    description: "Get the list of priviously crawled leads",
    href: "/lead-list",
    icon: FaFileCsv,
  },
];

const PageMenu = () => {
  return (
    <Popover className="relative">
      <div className="max-w-[%80] mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <GiHamburgerMenu className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-300" : "text-gray-50",
                      "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-300"
                    )}
                  >
                    <span>Support</span>
                    <FaAngleDown
                      className={classNames(
                        open ? "text-gray-300" : "text-gray-50",
                        "ml-2 h-5 w-5 group-hover:text-gray-300"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <Popover.Button
                              as={MenuItem}
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <MenuItem
            to="/terraform-plans"
            className="hidden md:inline text-base font-medium text-gray-50 hover:text-gray-300"
          >
            Infrastructure PRs
          </MenuItem>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-300" : "text-gray-50",
                      "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-300"
                    )}
                  >
                    <span>Sales</span>
                    <FaAngleDown
                      className={classNames(
                        open ? "text-gray-300" : "text-gray-50",
                        "ml-2 h-5 w-5 group-hover:text-gray-300"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {crawlerRessources.map((item) => (
                            <Popover.Button
                              as={MenuItem}
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <MenuItem
            to="/azdo-googlechat"
            className="hidden md:inline text-base font-medium text-gray-50 hover:text-gray-300"
          >
            Azure DevOps
          </MenuItem>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <FaRegWindowClose className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>

              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {resources.map((item) => (
                    <Popover.Button
                      as={MenuItem}
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
            <a
              href="/terraform-plans"
              className="text-base font-medium text-gray-50 hover:text-gray-300"
            >
              Infrastructure PRs
            </a>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
