import React, { useEffect } from "react";
import { useRequest } from "./Request";
import Loading from "./components/Loading";

const ProfileContext = React.createContext(null);

export const useProfile = () => React.useContext(ProfileContext);

const LoginScreen = () => {
  /* we redirect to the asp.net core /login url to start the login challenge (currently Google OAuth 2.0) */
  useEffect(() => {
    const baseUrl = document
      .getElementsByTagName("base")[0]
      .getAttribute("href");

    const redirect = window.location.pathname + window.location.search;
    window.location.href = `${baseUrl}login?redirect=${redirect}`;
  });
  return <></>;
};

export const ProfileProvider = ({ children }) => {
  const { data, error } = useRequest("/profile");

  if (error?.status === 401) return <LoginScreen />;
  if (error) return <div>failed to load</div>;
  if (!data) return <Loading />;

  return (
    <>
      <ProfileContext.Provider value={data}>{children}</ProfileContext.Provider>
    </>
  );
};
