import useSwr from "swr";
import Fetcher from "./Fetcher";

const baseUrl = () => document.getElementsByTagName("base")[0].getAttribute("href");

export const useRequest = (path, options, fetcher = Fetcher) => {
  const url = path ? path.replace(/^\//, baseUrl()) : path;
  return useSwr(url, fetcher, options);
};

export const useFetch = (path, options) => {
  const url = path ? path.replace(/^\//, baseUrl()) : path;
  return fetch(url, options);
};
