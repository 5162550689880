import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { signin } from "../../LeadCrawlerSignin";
import { Content, Header } from "../Blocks";

const countries = [
  { value: "Deutschland", label: "Germany" },
  { value: "IT", label: "Italy" },
  { value: "ES", label: "Spain" },
  { value: "UK", label: "England" },
];

const CreateLeadList = () => {
  return (
    <>
      <Header>
        <h1 id="tabelLabel">Lead Crawler</h1>
      </Header>
      <Content>
        <LeadForm />
      </Content>
    </>
  );
};

const LeadForm = () => {
  const [keyword, setKeyword] = useState();
  const [rating, setRating] = useState();
  const [amount, setAmount] = useState();
  const [percentage, setPercentage] = useState();
  const [country, setCountry] = useState();
  const [tld, setTld] = useState();
  const [displayResult, setDisplayResult] = useState(false);
  const [crawlerStarting, setCrawlerStarting] = useState(false);
  const submitForm = (e) => {
    e.preventDefault();
    setCrawlerStarting(true);
    signin().then((value) => startCrawler(value));
  };
  const handleChangeMultiSelect = (e) => {
    const updatedOptions = [...e.target.options]
      .filter((option) => option.selected)
      .map((x) => x.value);
    setCountry(updatedOptions);
  };
  const startCrawler = async (accessToken) => {
    fetch("https://api.14.plentymarkets.com/webcrawler-go/sellers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({
        keyword: keyword,
        rating: Number(rating),
        amount: Number(amount),
        percentage: Number(percentage),
        country: country,
        tld: tld,
      }),
    })
      .then((resp) => resp.json())
      .then((json) => setDisplayResult(json.status === "ok" ? true : false))
      .then(
        setKeyword(null),
        setAmount(null),
        setPercentage(null),
        setRating(null),
        setCountry(null),
        setTld(null),
        setCrawlerStarting(false)
      );
  };
  return (
    <div className="flex h-full">
      <div className="m-auto">
        <div>
          <div className="pt-5 bg-white rounded-lg shadow">
            <form action="#" onSubmit={submitForm}>
              <div className="flex"></div>
              <div className="px-5 pb-5">
                {displayResult && (
                  <>
                    <div className="p-2 bg-green-200 rounded-md">
                      <span className="py-8 text-lg font-bold">
                        Crawler started!
                      </span>
                    </div>
                  </>
                )}
                <label className="mx mt-2">Keyword</label>
                <input
                  required
                  onChange={(event) => {
                    setKeyword(event.target.value);
                  }}
                  value={keyword}
                  className="leadcrawler-input"
                />
                <label className="mx mt-2">Amount of leads</label>
                <input
                  required
                  onChange={(event) => {
                    setAmount(event.target.value);
                  }}
                  value={amount}
                  type="number"
                  min="0"
                  step="1"
                  className=" leadcrawler-input"
                />
                <label className="mx mt-2">Positive Rating Percentage</label>
                <input
                  required
                  onChange={(event) => {
                    setPercentage(event.target.value);
                  }}
                  value={percentage}
                  type="number"
                  min="0"
                  max="100"
                  step="1"
                  className=" leadcrawler-input"
                />
                <label className="mx mt-2">
                  Rating <small>(min. rating over the last 12 months)</small>
                </label>
                <input
                  required
                  onChange={(event) => {
                    setRating(event.target.value);
                  }}
                  value={rating}
                  type="number"
                  min="0"
                  step="1"
                  className=" leadcrawler-input"
                />
                <label className="mx mt-2">
                  Country <strong>optional</strong>
                </label>
                <select
                  onChange={handleChangeMultiSelect}
                  value={country}
                  multiple
                  className=" leadcrawler-input"
                >
                  {countries.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                <label className="mx mt-2">
                  TLD (use <strong>.de</strong> for ebay.de){" "}
                  <strong>optional</strong>
                </label>
                <select
                  onChange={(event) => {
                    setTld(event.target.value);
                  }}
                  value={tld}
                  className="leadcrawler-input"
                >
                  <option value="">select TLD</option>
                  <option value="de">de</option>
                  <option value="co.uk">co.uk</option>
                  <option value="fr">fr</option>
                  <option value="es">es</option>
                  <option value="it">it</option>
                </select>
              </div>
              <hr className="mt-4" />
              <div className="flex flex-row-reverse p-3">
                <div className="flex-initial pl-3">
                  <button
                    type="submit"
                    className="pl-2 mx-1"
                    disabled={crawlerStarting}
                    onSubmit={submitForm}
                  >
                    <span className="leadcrawler-startbutton ">
                      <FaPlay className="mr-3" />
                      Start Crawler
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLeadList;
