import { useFetch } from "./Request"

export const signin = async () => {
  let token;
  await useFetch('/leadcrawler/signin', {
    method: 'GET'
  }).then(response => response.json())
    .then(data => token = data['api_token']['access_token'])
  return token
 };

 
 