import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Content = ({ children }) => <div className="content">{children}</div>;

const Header = ({ children }) => <header>{children}</header>;

const useUrlParams = () => {
  const location = useLocation();
  return queryString.parse(location.search);
};

function NoCreatorPermission() {
  return (
    <div>
      You need to be a member of the{" "}
      <a href={"https://forum.plentymarkets.com/g/CategoryCreator"} target="_blank" rel="noreferrer">
        categoryCreator-Group
      </a>{" "}
      in discourse to use this tool. In case you need access please ask a
      discourse admin.
    </div>
  );
}

export { Content, Header, classNames, useUrlParams, NoCreatorPermission };
